import React from "react";
import { useRoutes } from "react-router-dom";
import AddTask from "../AddTask";
import Inputfield from "../Inputfield";


const AllRoutes = () => {
	let element = useRoutes([
		{
			path: "/",
			element: <AddTask />,
		},
		{
			path: "/inputfield",
			element: <Inputfield />,
		},
	]);
	return element;
};

export default AllRoutes;
