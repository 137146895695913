import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import tod from "../img/tod.png"

const AddTask = () => {

  const [name, setName] = React.useState("")
  const navigate = useNavigate()

  const AddUser = () => {
		window.localStorage.setItem("codelabname", JSON.stringify(name));

		navigate("/inputfield")
	};
  return (
    <Container>
      <ImageHolder>
        <Images src={tod}/>
        </ImageHolder>
      <Wrapper>
        
       <Top><h1>My Todo List</h1></Top>
       <P>A Professional project management software that helps you <br /> plan projects and keep 
       them on the right track</P>

       <P1>Please fill in your name to get started👇</P1>

       <Inputhold>
          <Input 
            onChange={(e) => {
              setName(e.target.value)
            }}
            placeholder="Enter your full-name" 
          />
       </Inputhold>
       {name !== "" ? (
        <Button onClick={AddUser} bg="#fff">
          Get Started
       </Button>
       ): (
        <Button  bg="silver">
          Get Started
       </Button>
       )}
      </Wrapper>
    </Container>
  )
}

export default AddTask
const Images = styled.img`
  /* margin-top: 30px; */
  height: 150px;
`
const ImageHolder = styled.div`
  width: 100%;
  height: 300px;
  background-color: white;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  display: none;

  @media screen and (max-width: 425px){
    display: flex;
  }
`
const Button = styled.button<{ bg: string }>`
  width: 380px;
  height: 45px;
  border: none;
  outline: none;
  background-color: ${(props) => props.bg};
  cursor: pointer;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;box-shadow: inset 0 0 5px black;
  transition: all 350ms;
  @media screen and (max-width: 375px){
    width: 290px;
  }

  :hover {
		transform: scale(0.96);
	}
`
const Circle = styled.div`
  width: 35px;
  height: 35px;
  background-color: #4095FF;
  color: white;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
  transition: all 350ms;
  :hover{
    transform: scale(0.80ms);
  }
`
const Input = styled.input`
  width: 300px;
  height: 100%;
  border: none;
  outline: none;
  padding-left: 20px;
  background: none;
`
const Inputhold = styled.div`
  width: 380px;
  height: 45px;
  display: flex;
  background-color: white;
  margin-top: 20px;
  overflow: hidden;
  align-items: center;
  justify-content: space-between;
  border-radius: 50px;
  box-shadow: inset 0 0 5px black;

  @media screen and (max-width: 375px){
    width: 290px;
  }
`
const P1 = styled.div`
  color: white;
  margin-top: 25px;
`
const P = styled.div`
  color: white;
  text-align: center;
`
const Top = styled.div`
  h1{
    color: white;
    font-size: 35px;
  }
`
const Image = styled.img`
 height: 430px;
`
const Right = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  /* background-color: green; */
  align-items: center;
`
const Left = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: red;
`

const Wrapper = styled.div`
  width: 95%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-items: center;
`

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  background-color: #4095FF;
  align-items: center;
  flex-direction: column;
`
// box-shadow: inset 0 0 5px black;