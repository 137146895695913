import React from 'react'
import AddTask from './Components/AddTask'
import AllRoutes from './Components/AllRoutes/AllRoutes'

const App = () => {
  return (
    <div>
      <AllRoutes />
    </div>
  )
}

export default App