import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import good from "../img/goods.png"

interface data {
  todo: string;
  id: number;
  status: boolean;
  Describe: string;
  start: string;
  end: string;
  time: string;
}
const Inputfield = () => {
	const [todoValue, setTodoValue] = React.useState("");
	const [inputValue, setInputValue] = React.useState("")
	const [data, setData] = React.useState<data[]>([]);
	const [isEditing, setIsEditing] = useState(false);
  const [currentTodo, setCurrentTodo] = useState({});
  const [show, setShow] = useState<boolean>(false)
const [notshow, setNotShow] = useState<boolean>(false)


function handleEditInputChang(e: any) {
    
}

const Not = () => {
	setNotShow(!notshow)
}

  const Shows = () => {
	setShow(!show)
  }


   let idData: number = data.length + 1;

  //   creating task
  const addNewTask = () => {
    // sorting algoritm
    const sortinfo = (x: any) => {
      return (a: any, b: any) => {
        if (a[x] < b[x]) {
          return a[x];
        } else if (a[x] > b[x]) {
          return -1;
        }
        return 0;
      };
    };

    // time
    const now = new Date();

    setDate(now.toDateString());
    let hr = now.getHours().toString();
    let min = now.getMinutes().toString();
    let sec = now.getSeconds().toString();
    // let aporpm = now.toString();
    var aMorPm = hr >= "12" ? "pm" : "am";


    // get all data
    setData((prev) =>
      [
        ...prev,
        {
          status: false,
          todo: todoValue,
          id: idData,
          Describe: des,
          start: start,
          end: end,
          time: `${hr}:${min}:${sec}:${aMorPm}`,
        },
      ].sort(sortinfo("id"))
    );
  };

  // start and end
  const [start, setStart] = React.useState("");
  const [end, setEnd] = React.useState("");

  // date
  const [date, setDate] = React.useState<any>();
 

  //   delete task;
  const deleteTask = (id: number) => {
    let deleteData = data.filter((e) => e.id !== id);
    setData(deleteData);
  };

  // desctiption
  const [des, setdes] = React.useState("");

  //   edit
  const [edit, setedit] = React.useState(-1);
  // boolean operator
  const [falses, setfalses] = React.useState(false);
  // switch edit and update button
  const chageButton = () => {
    setfalses(!falses);
  };

  // capture edit input
  const [input, setinput] = React.useState("");

  const updateButton = () => {
    setfalses(!true);
    data[0].todo = input;
  };

  const editdata = (id: number) => {
    setedit(id);
  };

  // done
  const [done, setDone] = React.useState<any>(false);
  const changeDoneState = () => {
    setDone(true);
	setShow(true);
    data[0].status = true;
    // setShow(true)
  };

  return (
    <Container>
        <Wrapper>
            <Left>
                <User><h1>Welcome User</h1></User>
                <P>Create a task for today👇</P>

                <Inputhold>
                    <Input 
                    onChange={(e) => {
                        setTodoValue(e.target.value)
                    }}
                    placeholder='Enter your task'/>
                </Inputhold>

                <DateTimeHold>
                    <Time><h2>Set a Time</h2></Time>
                    <Hold>
                        <Timehold><h3>Start:</h3>  <Start type="time" onChange={(e) => {setStart(e.target.value)}}/></Timehold>
                        <Timehold><h3>End:</h3>  <Start type="time" onChange={(e) => {setEnd(e.target.value)}}/></Timehold>
                    </Hold>
                </DateTimeHold>

                <Submit>
                    {todoValue !== "" ? (
                        <Subm onClick={addNewTask} bg="white">
                            Submit
                        </Subm>
                    ) : (
                        <Subm bg="silver">
                            Submit
                        </Subm>
                    )}
                </Submit>
            </Left>
            <Right>
                <Tasks><h2>All Tasks</h2></Tasks>

                <Cardhold>
                    {data.map((data) => (
                        <Card key={data.id}>
                            {data.id === edit ? (
                                <input defaultValue={data.todo} />
                            ) : (
                                <Tittle>{data.todo}</Tittle>
                            )}

                            <Dis>
							    {data.Describe}
						    </Dis>
                            <Dates>{date}</Dates>
                            <Times>
 									<Starts>
 										Start: {data.start}
 									</Starts>
 									<End>End: {data.end}</End>
 								</Times>

                            {data.id === edit && falses? (
                                <button 
                                onClick={() => {
                                    updateButton()
                                    editdata(data.id)
                                }}
                                >
                                    Update
                                </button>
                            ) : (
                                <Buthold>
                                <button 
                                   onClick={() => {
                                    chageButton()
                                    editdata(data.id)
                                   }}
                                >
                                    Edit
                                </button>

                                <Check type="checkbox"/>

                                <button 
                                onClick={() => {
                                    // changeDoneState()
                                    setDone(data.id)
                                }}
                                >
                                    Done
                                </button>


                                <button 
                                onClick={() => {
                                    deleteTask(data.id)
                                }}
                                >
                                    Delete
                                </button>
                            </Buthold>
                            )}
                            {data.id === done  ? (
 							<Modal dn="flex">
 							<Image src={good}/>
 							<Texts>Congratulations</Texts>
 							<Task>Task Completed</Task>
							
 						</Modal>
 						) : (
							<Modal dn="">
 							<Image src={good}/>
 							<Texts>Congratulations</Texts>
 							<Task>Task Completed</Task>
							
 						</Modal>
						)}
                        </Card>
                    ))}
                </Cardhold>
            </Right>
        </Wrapper>
    </Container>
  )
}

export default Inputfield
const Task = styled.div`
	
`
const Texts = styled.div`
	margin-bottom: 15px;
	font-size: 25px;
`
const Image = styled.img`
	height: 50px;
	margin-bottom: 20px;
`
const Modal = styled.div<{ dn: string }>`
	width: 100%;
	height: 100%;
	background-color: white;
	position: absolute;
	top: 0;
	left: 0;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	display: ${({ dn }) => (dn ? "flex" : "none")};

	animation-name: grow-box;
	animation-duration: 0.4s;
	animation-timing-function: ease-in-out;

	@keyframes grow-box {
		0% {opacity: 0.2; }
		25% {opacity: 0.4; }
		50% {opacity: 0.6; }
		75% {opacity: 0.8; }
		100% {opacity: 0.9}
	}
`
const End = styled.div`
`
const Starts = styled.div`
	
`
const Times = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin-top: 15px;
`
const Dates = styled.div`
	width: 220px;
    height: 25px;
    border: 1px solid #4095FF;
    display: flex;
    justify-content: center;
    align-items: center;
`
const Check = styled.input`
	position: absolute;
	right: 10px;
	height: 20px;
	width: 20px;
    top: 10px
`
const Dis = styled.div`
`
const Hold = styled.div`
    width: 100%;
	display: flex;
	align-items: center;
	/* justify-content: space-between; */
    flex-direction: column;
`
const Inputs = styled.input`
	height: 50px;
	width: 380px;
	padding-left: 10px;
	padding-right: 10px;
	border: none;
	outline: none;
	border-radius: 5px;
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`
const DateTimeHold = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`
const Buthold = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    button {
		margin-right: 5px;
		height: 30px;
		width: 90px;
		margin-top: 20px;
		cursor: pointer;
	}
`
const Tittle = styled.div`
margin-bottom: 10px;
`
const Card = styled.div`
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	/* height: 200px; */
	width: 300px;
	background-color: white;
	display: flex;
	/* justify-content: center; */
	align-items: left;
	flex-direction: column;
	text-align: left;
	padding: 30px;
	border-radius: 5px;
	position: relative;
	margin: 10px;
    @media screen and (max-width: 375px){
    width: 230px;
    /* align-items: center; */
  }
`
const Cardhold = styled.div`
    display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
`
const Tasks = styled.div`
    h2{
        color: black;
    }
`
const Right = styled.div`
    width: calc(100% - 400px);
    height: 100%;
    /* background-color: red; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-left: 400px; */
    flex-direction: column;
    @media screen and (max-width: 768px) {
        width: 100%;
        /* position: absolute; */
    }
`
const Subm = styled.button<{ bg: string }>`
    width: 350px;
  height: 45px;
  border: none;
  outline: none;
background-color: ${(props) => props.bg};
  cursor: pointer;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  /* color: white; */
  font-size: 20px;
  align-items: center;
  margin-top: 15px;box-shadow: inset 0 0 5px black;
  transition: all 350ms;
  @media screen and (max-width: 375px){
    width: 260px;
    font-size: 17px;
  }
  
  :hover {
		transform: scale(0.96);
	}
`
const Submit = styled.div`
    
`
const Start = styled.input`
    margin-left: 10px;
    width: 270px;
    height: 45px;
    border: none;
    outline: none;
    padding-left: 20px;
    box-shadow: inset 0 0 5px black;
    border-radius: 50px;
    padding-left: 10px;
    padding-right: 10px;
    @media screen and (max-width: 375px){
    width: 200px;
  }
`
const Timehold = styled.div`
    display: flex;
    color: white;
    align-items: center;
    h3{
        color: white;
    }
`
const Time = styled.div`
    h2{
        color: white;
    }
`

const Input = styled.input`
  width: 300px;
  height: 100%;
  border: none;
  outline: none;
  padding-left: 20px;
  background: none;
`
const Inputhold = styled.div`
  width: 350px;
  height: 45px;
  display: flex;
  background-color: white;
  margin-top: 20px;
  overflow: hidden;
  align-items: center;
  justify-content: space-between;
  border-radius: 50px;
  box-shadow: inset 0 0 5px black;

  @media screen and (max-width: 375px){
    width: 290px;
  }
`
const P = styled.div`
    color: white;
`
const User = styled.div`
    margin-top: 108px;
    h1{
        color: white;
    }
`
const Left = styled.div`
    width: 400px;
    height: 100%;
    display: flex;
    background-color: #4095FF;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* position: fixed; */
    padding-bottom: 120px;
    @media screen and (max-width: 768px) {
        width: 100%;
        /* position: absolute; */
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
        height: 40%;
    }
    
`
const Top = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
`

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    /* justify-content: center;
    align-items: center; */
    background-color: white;
    flex-wrap: wrap;
    @media screen and (max-width: 425px) {
        /* flex-wrap: wrap; */
        /* width: 95%; */
    }
`

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
`